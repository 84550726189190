/* eslint-disable */
import * as Highcharts from 'highcharts';
import Xrange from 'highcharts/modules/xrange';

Xrange(Highcharts);

/*Dynamic Chart Data */
export const fiveYearPlOverviewLabels = {
  exportChartName: 'PROFIT_AND_LOSS_CHART',
  chartTitle: '5-Yr P&L Overview',
  xAxisLabel: 'Years',
  yAxisLabel: 'Cost (in $)',
};

export const featureNames = {
  cloudFinancialManagement: 'Cloud Financial Management',
  spendOptimization: 'Spend Optimization',
  awsDiscover: 'AWS Discover',
  applicationDecomposition: 'Application Decomposition',
  applicationDependencyMapping: 'Application Dependency Mapping',
  applicationDispositioning: 'Application Dispositioning',
  applicationMigrationSequence: 'Application Migration Sequence',
  appsPassed: 'Apps Passed',
  artifactsDiscovery: 'Artifacts Discovery',
  assessmentSummary: 'Assessment Summary',
  availabilitySLA: 'Availability SLA',
  azureDiscover: 'Azure Discover',
  blockersAndCausesForDelay: 'Blockers and Causes for Delay',
  businessPrioritization: 'Business Prioritization',
  businessProcessOptimization: 'Business Process Optimization & Visualization',
  businessRealizationMonthlySavingTracking: 'Business Realization - Monthly saving tracking',
  changeManagement: 'Change Management',
  chaosEngineering: 'Chaos Engineering',
  cloudCostAllocation: 'Cloud Cost Allocation',
  cloudCostSummary: 'Cloud Cost Summary',
  cloudCostVisibility: 'Cloud Cost Visibility',
  cloudEconomics: 'Cloud Economics',
  cloudFinancialAnalysis: 'Cloud Financial Analysis',
  cloudFoundationGovernanceAssessment: 'Cloud Foundation Governance Assessment',
  cloudFoundationReadiness: 'Cloud Foundation Readiness',
  cloudFoundationStrategy: 'Cloud Foundation Strategy',
  cloudPlatform: 'Cloud Platform',
  cloudSpendOptimization: 'Cloud Spend Optimization',
  cloudSpends: 'Cloud Spends',
  cloudSuitabilityPlacement: 'Cloud Suitability & Placement',
  cloudTechValueBenefitOptimization: 'Cloud Tech Value Benefit Optimization',
  codeQuality: 'Code Quality',
  committedVsForecastedSpend: 'Committed vs Forecasted Spend',
  componentAPITesting: 'Component (API) Testing',
  containerInsights: 'Container Insights',
  containerMigration: 'Container Migration',
  costOptimizationInsights: 'Cost Optimization Insights',
  currentAndForecastedSpend: 'Current And Forecasted Spend',
  depPKubernetes: 'DEP - Deloitte Kubernetes Services**',
  depCopilot: 'DEP - GitHub Copilot**',
  dataCenterSaving: 'Data Center Saving',
  databaseDecomposition: 'Database Decomposition',
  defectAgeing: 'Defect Ageing',
  defectsLogged: 'Defects Logged',
  deloitteInsights: 'Deloitte Insights',
  deploymentPipelines: 'Deployment pipelines',
  designBlueprint: 'Design Blueprint',
  environmentBuild: 'Environment Build',
  environmentDeployConfiguration: 'Environment Deploy & Configuration',
  environmentHealth: 'Environment Health',
  environmentValidation: 'Environment Validation',
  financialGovernance: 'Financial Governance',
  financials: 'Financials',
  foundationArchitecturePatterns: 'Foundation Architecture Patterns',
  foundationHealthAssessment: 'Foundation Health Assessment',
  gcpDiscover: 'GCP Discover',
  greenCloud: 'Green Cloud',
  highLevelStatus: 'High Level Status',
  implementationPlanTemplates: 'Implementation Plan Templates',
  incidentDetails: 'Incident Details',
  incidentSummaryByStatus: 'Incident Summary By Status',
  incidentTrend: 'Incident Trend',
  infrastructure: 'Infrastructure',
  infrastructureDiscovery: 'Infrastructure Discovery',
  infrastructureOLA: 'Infrastructure OLA',
  infrastructureTesting: 'Infrastructure Testing',
  languageMigration: 'Language Migration',
  languageMigrationMainframeToJavaNet: 'Language Migration - Mainframe to Java/.Net',
  languageMigrationMainframeToSalesforce: 'Language Migration - Mainframe to Salesforce',
  learningCenter: 'Learning Center',
  legacyDatabaseInventory: 'Legacy Database Inventory',
  migrateDBToPaaSHomogenous: 'Migrate DB to PaaS (Homogenous)',
  migrateSprintActivities: 'Migrate Sprint Activities',
  migrateToContainers: 'Migrate to Containers',
  migrationDispositionChangesOverTime: 'Migration Progress Planned Vs Completed',
  migrationProgress: 'Migration Progress',
  migrationStrategies: 'Migration Strategies',
  modernizeSprintActivities: 'Modernize Sprint Activities',
  monolithicApplicationDecomposition: 'Monolithic Application Decomposition',
  multiCloudSpendOptimization: 'Multi-cloud Spend Optimization',
  numberOfTestCases: 'Number of Test Cases',
  ocmOrganizationalChangeManagementMetrics: 'OCM (Organizational Change Management) Metrics',
  observability: 'Observability',
  observabilitySecurity: 'Observability - Security',
  observabilityCMDB: 'Observability-CMDB',
  observabilityInventory: 'Observability-inventory',
  openChangeRequests: 'Open Change Requests',
  operationModelAssessment: 'Operation Model Assessment',
  operationalGovernance: 'Operational Governance',
  oracleDiscover: 'Oracle Discover',
  plannedMigrationWaves: 'Planned Migration Waves',
  platformMigration: 'Platform Migration',
  portfolioAssessmentMetrics: 'Portfolio Assessment Metrics',
  projectSummary: 'Project Summary',
  raidd: 'RAIDD',
  risksIssues: 'Risks / Issues',
  sqlDiscovery: 'SQL Discovery',
  sreReadiness: 'SRE Readiness',
  savingsManagement: 'Savings management',
  serverResourceUse: 'Server Resource Use',
  serverOSPatches: 'Server/OS Patches',
  severityVsCreatedOn: 'Severity Vs Created on',
  surveyCompletionMetrics: 'Survey Completion Metrics',
  tcoInsights: 'TCO Insights',
  tableCount: 'Table Count',
  techValueBenefitRealization: 'Tech Value Benefit Realization',
  testCasesPlannedVsExecuted: 'Test Cases Planned Vs Executed',
  testRunStatus: 'Test Run Status',
  testStatusReport: 'Test Status Report',
  thirdPartyIntegrations: 'Third-party Integrations',
  turboCodeDashboard: 'TurboCode Dashboard',
  upgradeReinstall: 'Upgrade / Re-install',
  vmWareVMCRelocate: 'VMWare – VMC (Relocate)',
  vulnerabilityScans: 'Vulnerability Scans',
  workstreamAndTeamDependency: 'Workstream and Team Dependency',
  yourScore: 'Your Score',
  TestStatusReport: 'Test Status Report',
  DEPCodeScan: 'DEP - Code Scan',
  DEPCodeSpace: 'DEP - Codespace',
  problemInsights: 'Problem Insights',
  kms: 'Kubernetes Management Services',
  transitions: 'Transitions',
  riskSummary: 'Risk Summary',
  issueSummary: 'Issue Summary',
  activeSummary: 'Active Summary',
  programAerodynamics: 'Program Aerodynamics\u2122 Digital Playbook',
  automatedVUCAAssessment: 'Automated VUCA Assessment',
  digitalMaturityDiagnostic: 'Digital Maturity Diagnostic',
  serversByUpgrade: 'Server Upgrade Status',
  programStatus: 'Program Status',
  raidLog: 'RAID Log',
  resourceManagement: 'Resource Management',
  minutesOfMeetings: 'Minutes of Meetings',
  weeklyStatusReports: 'Weekly Status Reports',
  designOptimization: 'Design Optimization',
  userStories: 'User Stories',
  testCases: 'Test Cases',
};

export const capabilitiyNames = {
  cloudFinancialAnalysis: 'Cloud Financial Analysis',
  cloudFinancialManagement: 'Cloud Financial Management',
  cloudNativeAppDevelopment: 'Cloud Native App Development',
  testManagement: 'Test Management',
};

export const domainLabels = {
  ProjectManagement: 'Project Management',
  Dev: 'Dev',
  Testing: 'Testing',
  ProcessOps: 'Process/Ops',
};

export const smartPMOFeaturesList = [
  'High Level Status',
  'Risks / Issues',
  'Change Management',
  'Financials',
  'Project Summary',
];

export const summaryCapabilityCardLabels = {
  noCapabilitiesEnabled: 'No journey capabilities are available for the',
  viewMore: 'View More',
  configurationPending: 'Configuration Pending',
};

export const fiveYearPlOverviewAPIData = {
  'profitAndLossMap': {
    'Year0': {
      'Compute': {
        'onPremiseCost': 6270.225,
        'awsCost': 6270.225,
        'percReductionDueToAWS': 0.0,
      },
      'Storage': {
        'onPremiseCost': 7027.119689,
        'awsCost': 7027.119689,
        'percReductionDueToAWS': 0.0,
      },
      'Network': {
        'onPremiseCost': 73584.949153,
        'awsCost': 73584.949153,
        'percReductionDueToAWS': 0.0,
      },
      'Database License': {
        'onPremiseCost': 33453.0,
        'awsCost': 33453.0,
        'percReductionDueToAWS': 0.0,
      },
      'Facilities': {
        'onPremiseCost': 418545.707696,
        'awsCost': 418545.707696,
        'percReductionDueToAWS': 0.0,
      },
      'Internal Labor': {
        'onPremiseCost': 86200.0,
        'awsCost': 86200.0,
        'percReductionDueToAWS': 0.0,
      },
      'One Time Build Costs': {
        'onPremiseCost': 0,
        'awsCost': 0e-8,
        'percReductionDueToAWS': 0,
      },
      'Migration Cost': {
        'onPremiseCost': 0,
        'awsCost': 0e-8,
        'percReductionDueToAWS': 0,
      },
      'Miscellaneous Cost': {
        'onPremiseCost': 0,
        'awsCost': 0e-8,
        'percReductionDueToAWS': 0,
      },
      'Analytics': {
        'onPremiseCost': 0,
        'awsCost': 0,
        'percReductionDueToAWS': 0,
      },
      'TOTAL': {
        'onPremiseCost': 625081.001538,
        'awsCost': 625081.001538,
        'percReductionDueToAWS': 0.0,
        'profitAndLoss': 0e-8,
        'profitAndLossInPerc': 0,
        'belowTheLineProfit': 0,
        'totalProfit': 0e-8,
      },
    },
    'Year1': {
      'Compute': {
        'onPremiseCost': 6270.225,
        'awsCost': 4309.78665,
        'percReductionDueToAWS': -0.31,
      },
      'Storage': {
        'onPremiseCost': 7027.119689,
        'awsCost': 4315.14173125,
        'percReductionDueToAWS': -0.39,
      },
      'Network': {
        'onPremiseCost': 73584.949153,
        'awsCost': 20586.53225125,
        'percReductionDueToAWS': -0.72,
      },
      'Database License': {
        'onPremiseCost': 33453.0,
        'awsCost': 33453.0,
        'percReductionDueToAWS': 0.0,
      },
      'Facilities': {
        'onPremiseCost': 418545.707696,
        'awsCost': 104636.426924,
        'percReductionDueToAWS': -0.75,
      },
      'Internal Labor': {
        'onPremiseCost': 86200.0,
        'awsCost': 68308.42,
        'percReductionDueToAWS': -0.21,
      },
      'One Time Build Costs': {
        'onPremiseCost': 0,
        'awsCost': 13332.0,
        'percReductionDueToAWS': 0,
      },
      'Migration Cost': {
        'onPremiseCost': 0,
        'awsCost': 9.99999999,
        'percReductionDueToAWS': 0,
      },
      'Miscellaneous Cost': {
        'onPremiseCost': 0,
        'awsCost': 92556.8,
        'percReductionDueToAWS': 0,
      },
      'Analytics': {
        'onPremiseCost': 0.0,
        'awsCost': 0.0,
        'percReductionDueToAWS': 0,
      },
      'TOTAL': {
        'onPremiseCost': 625081.001538,
        'awsCost': 341508.10755649,
        'percReductionDueToAWS': 0.45,
        'profitAndLoss': 283572.89398151,
        'profitAndLossInPerc': 0.453658,
        'belowTheLineProfit': 134968,
        'totalProfit': 418540.89398151,
      },
    },
    'Year2': {
      'Compute': {
        'onPremiseCost': 6270.225,
        'awsCost': 2742.2304,
        'percReductionDueToAWS': -0.56,
      },
      'Storage': {
        'onPremiseCost': 7027.119689,
        'awsCost': 2558.361809,
        'percReductionDueToAWS': -0.64,
      },
      'Network': {
        'onPremiseCost': 73584.949153,
        'awsCost': 2190.294963,
        'percReductionDueToAWS': -0.97,
      },
      'Database License': {
        'onPremiseCost': 33453.0,
        'awsCost': 33453.0,
        'percReductionDueToAWS': 0.0,
      },
      'Facilities': {
        'onPremiseCost': 418545.707696,
        'awsCost': 0e-8,
        'percReductionDueToAWS': -1.0,
      },
      'Internal Labor': {
        'onPremiseCost': 86200.0,
        'awsCost': 46758.42,
        'percReductionDueToAWS': -0.46,
      },
      'One Time Build Costs': {
        'onPremiseCost': 0,
        'awsCost': 13332.0,
        'percReductionDueToAWS': 0,
      },
      'Migration Cost': {
        'onPremiseCost': 0,
        'awsCost': 9.99999999,
        'percReductionDueToAWS': 0,
      },
      'Miscellaneous Cost': {
        'onPremiseCost': 0,
        'awsCost': 92556.8,
        'percReductionDueToAWS': 0,
      },
      'Analytics': {
        'onPremiseCost': 0.0,
        'awsCost': 0.0,
        'percReductionDueToAWS': 0,
      },
      'TOTAL': {
        'onPremiseCost': 625081.001538,
        'awsCost': 193601.10717199,
        'percReductionDueToAWS': 0.69,
        'profitAndLoss': 431479.89436601,
        'profitAndLossInPerc': 0.690278,
        'belowTheLineProfit': 48000,
        'totalProfit': 479479.89436601,
      },
    },
    'Year3': {
      'Compute': {
        'onPremiseCost': 6270.225,
        'awsCost': 2742.2304,
        'percReductionDueToAWS': -0.56,
      },
      'Storage': {
        'onPremiseCost': 7027.119689,
        'awsCost': 2558.361809,
        'percReductionDueToAWS': -0.64,
      },
      'Network': {
        'onPremiseCost': 73584.949153,
        'awsCost': 2190.294963,
        'percReductionDueToAWS': -0.97,
      },
      'Database License': {
        'onPremiseCost': 33453.0,
        'awsCost': 33453.0,
        'percReductionDueToAWS': 0.0,
      },
      'Facilities': {
        'onPremiseCost': 418545.707696,
        'awsCost': 0e-8,
        'percReductionDueToAWS': -1.0,
      },
      'Internal Labor': {
        'onPremiseCost': 86200.0,
        'awsCost': 46758.42,
        'percReductionDueToAWS': -0.46,
      },
      'One Time Build Costs': {
        'onPremiseCost': 0,
        'awsCost': 13332.0,
        'percReductionDueToAWS': 0,
      },
      'Migration Cost': {
        'onPremiseCost': 0,
        'awsCost': 9.99999999,
        'percReductionDueToAWS': 0,
      },
      'Miscellaneous Cost': {
        'onPremiseCost': 0,
        'awsCost': 92556.8,
        'percReductionDueToAWS': 0,
      },
      'Analytics': {
        'onPremiseCost': 0.0,
        'awsCost': 0.0,
        'percReductionDueToAWS': 0,
      },
      'TOTAL': {
        'onPremiseCost': 625081.001538,
        'awsCost': 193601.10717199,
        'percReductionDueToAWS': 0.69,
        'profitAndLoss': 431479.89436601,
        'profitAndLossInPerc': 0.690278,
        'belowTheLineProfit': 0,
        'totalProfit': 431479.89436601,
      },
    },
    'Year4': {
      'Compute': {
        'onPremiseCost': 6270.225,
        'awsCost': 2742.2304,
        'percReductionDueToAWS': -0.56,
      },
      'Storage': {
        'onPremiseCost': 7027.119689,
        'awsCost': 2558.361809,
        'percReductionDueToAWS': -0.64,
      },
      'Network': {
        'onPremiseCost': 73584.949153,
        'awsCost': 2190.294963,
        'percReductionDueToAWS': -0.97,
      },
      'Database License': {
        'onPremiseCost': 33453.0,
        'awsCost': 33453.0,
        'percReductionDueToAWS': 0.0,
      },
      'Facilities': {
        'onPremiseCost': 418545.707696,
        'awsCost': 0e-8,
        'percReductionDueToAWS': -1.0,
      },
      'Internal Labor': {
        'onPremiseCost': 86200.0,
        'awsCost': 46758.42,
        'percReductionDueToAWS': -0.46,
      },
      'One Time Build Costs': {
        'onPremiseCost': 0,
        'awsCost': 0e-8,
        'percReductionDueToAWS': 0,
      },
      'Migration Cost': {
        'onPremiseCost': 0,
        'awsCost': 0e-8,
        'percReductionDueToAWS': 0,
      },
      'Miscellaneous Cost': {
        'onPremiseCost': 0,
        'awsCost': 92556.8,
        'percReductionDueToAWS': 0,
      },
      'Analytics': {
        'onPremiseCost': 0.0,
        'awsCost': 0.0,
        'percReductionDueToAWS': 0,
      },
      'TOTAL': {
        'onPremiseCost': 625081.001538,
        'awsCost': 180259.107172,
        'percReductionDueToAWS': 0.71,
        'profitAndLoss': 444821.894366,
        'profitAndLossInPerc': 0.711623,
        'belowTheLineProfit': 0,
        'totalProfit': 444821.894366,
      },
    },
    'Year5': {
      'Compute': {
        'onPremiseCost': 6270.225,
        'awsCost': 2742.2304,
        'percReductionDueToAWS': -0.56,
      },
      'Storage': {
        'onPremiseCost': 7027.119689,
        'awsCost': 2558.361809,
        'percReductionDueToAWS': -0.64,
      },
      'Network': {
        'onPremiseCost': 73584.949153,
        'awsCost': 2190.294963,
        'percReductionDueToAWS': -0.97,
      },
      'Database License': {
        'onPremiseCost': 33453.0,
        'awsCost': 33453.0,
        'percReductionDueToAWS': 0.0,
      },
      'Facilities': {
        'onPremiseCost': 418545.707696,
        'awsCost': 0e-8,
        'percReductionDueToAWS': -1.0,
      },
      'Internal Labor': {
        'onPremiseCost': 86200.0,
        'awsCost': 46758.42,
        'percReductionDueToAWS': -0.46,
      },
      'One Time Build Costs': {
        'onPremiseCost': 0,
        'awsCost': 0e-8,
        'percReductionDueToAWS': 0,
      },
      'Migration Cost': {
        'onPremiseCost': 0,
        'awsCost': 0e-8,
        'percReductionDueToAWS': 0,
      },
      'Miscellaneous Cost': {
        'onPremiseCost': 0,
        'awsCost': 92556.8,
        'percReductionDueToAWS': 0,
      },
      'Analytics': {
        'onPremiseCost': 0.0,
        'awsCost': 0.0,
        'percReductionDueToAWS': 0,
      },
      'TOTAL': {
        'onPremiseCost': 625081.001538,
        'awsCost': 180259.107172,
        'percReductionDueToAWS': 0.71,
        'profitAndLoss': 444821.894366,
        'profitAndLossInPerc': 0.711623,
        'belowTheLineProfit': 0,
        'totalProfit': 444821.894366,
      },
    },
  },
  'baseYear': '2024',
};
export enum costComparisionTypes {
  COMPUTE_COST = 'Compute Cost',
  STORAGE_COST = 'Storage Cost',
}
export const costComparisionLabels = {
  computeCostExportChartName: 'COMPUTE_COST',
  storageCostExportChartName: 'STORAGE_COST_COMPARISION_CHART',
  computeCostChartTitle: 'BAU vs Cloud Compute Cost Comparison',
  storageCostChartTitle: 'BAU vs Cloud Storage Cost Comparison',
  xAxisLabel: 'Years',
  yAxisLabel: 'Cost (in $)',
};
export const storageCostAPIData = {
  'baseYear': 2022,
  'listofBAUStorage': [
    7027.119689, 7027.119689, 7027.119689, 7027.119689, 7027.119689, 7027.119689,
  ],
  'listofCloudStorage': [
    7027.119689, 4315.14173125, 2558.361809, 2558.361809, 2558.361809, 2558.361809,
  ],
  'onPremise': {
    'Primary': 6144.0,
    'Backup': 883.119689,
    'Deep Archive': 0.0,
    'Database Storage': 0.0,
    'Total Annual Cost': 7027.119689,
  },
  'cloud': {
    'S3': 0.0,
    'EBS': 2558.3616,
    'Glacier': 0.0,
    'FSx/EFS': 0.0,
    'Storage IOPS': 2.09e-4,
    'RDS Storage': 0.0,
    'Total Annual Cost': 2558.361809,
  },
  'listOfRunRatePl': [
    {
      'bauFYYear1': 7027.119689,
      'storageSavings': -2711.9779577500003,
      'onPremiseCostReduction': -5270.33976675,
      'nonOptimizedAWSUsage': 2558.361809,
      'storageOptimizedSavings': 0.0,
      'cloudRunRate': 4315.14173125,
      'oneTimeAdjustment': -16000.0,
      'adjustmentVarianceWithFYYear1': -18711.97795775,
    },
    {
      'bauFYYear1': 7027.119689,
      'storageSavings': -4468.75788,
      'onPremiseCostReduction': -7027.119689,
      'nonOptimizedAWSUsage': 2558.361809,
      'storageOptimizedSavings': 0.0,
      'cloudRunRate': 2558.361809,
      'oneTimeAdjustment': -8000.0,
      'adjustmentVarianceWithFYYear1': -12468.757880000001,
    },
    {
      'bauFYYear1': 7027.119689,
      'storageSavings': -4468.75788,
      'onPremiseCostReduction': -7027.119689,
      'nonOptimizedAWSUsage': 2558.361809,
      'storageOptimizedSavings': 0.0,
      'cloudRunRate': 2558.361809,
      'oneTimeAdjustment': 0.0,
      'adjustmentVarianceWithFYYear1': -4468.75788,
    },
    {
      'bauFYYear1': 7027.119689,
      'storageSavings': -4468.75788,
      'onPremiseCostReduction': -7027.119689,
      'nonOptimizedAWSUsage': 2558.361809,
      'storageOptimizedSavings': 0.0,
      'cloudRunRate': 2558.361809,
      'oneTimeAdjustment': 0.0,
      'adjustmentVarianceWithFYYear1': -4468.75788,
    },
    {
      'bauFYYear1': 7027.119689,
      'storageSavings': -4468.75788,
      'onPremiseCostReduction': -7027.119689,
      'nonOptimizedAWSUsage': 2558.361809,
      'storageOptimizedSavings': 0.0,
      'cloudRunRate': 2558.361809,
      'oneTimeAdjustment': 0.0,
      'adjustmentVarianceWithFYYear1': -4468.75788,
    },
    {
      'bauFYYear1': 35135.598445,
      'storageSavings': -20587.00947775,
      'onPremiseCostReduction': -33378.81852275,
      'nonOptimizedAWSUsage': 12791.809045,
      'storageOptimizedSavings': 0.0,
      'cloudRunRate': 14548.58896725,
      'oneTimeAdjustment': -24000.0,
      'adjustmentVarianceWithFYYear1': -44587.00947775,
    },
  ],
  'listOfCostBreakdown': [
    {
      'bauFYYear1': 7027.119689,
      'primary': 6144.0,
      'backup': 883.119689,
      'deepArchive': 0.0,
      'bauDatabaseStorage': 0.0,
      'totalCloudCosts': 4315.14173125,
      'ebs': 2558.3616,
      's3': 0.0,
      'glacierPlusS3IA': 0.0,
      'fSxEFS': 0.0,
      'iops': 2.09e-4,
      'rdsStorage': 0.0,
      'remainingOnPremisePrimary': 1536.0,
      'remainingOnPremiseBackup': 220.77992225,
      'remainingOnPremiseDeepArchive': 0.0,
      'varianceWithFYYear1': 2711.9779577500003,
    },
    {
      'bauFYYear1': 7027.119689,
      'primary': 6144.0,
      'backup': 883.119689,
      'deepArchive': 0.0,
      'bauDatabaseStorage': 0.0,
      'totalCloudCosts': 2558.361809,
      'ebs': 2558.3616,
      's3': 0.0,
      'glacierPlusS3IA': 0.0,
      'fSxEFS': 0.0,
      'iops': 2.09e-4,
      'rdsStorage': 0.0,
      'remainingOnPremisePrimary': 0.0,
      'remainingOnPremiseBackup': 0.0,
      'remainingOnPremiseDeepArchive': 0.0,
      'varianceWithFYYear1': 4468.75788,
    },
    {
      'bauFYYear1': 7027.119689,
      'primary': 6144.0,
      'backup': 883.119689,
      'deepArchive': 0.0,
      'bauDatabaseStorage': 0.0,
      'totalCloudCosts': 2558.361809,
      'ebs': 2558.3616,
      's3': 0.0,
      'glacierPlusS3IA': 0.0,
      'fSxEFS': 0.0,
      'iops': 2.09e-4,
      'rdsStorage': 0.0,
      'remainingOnPremisePrimary': 0.0,
      'remainingOnPremiseBackup': 0.0,
      'remainingOnPremiseDeepArchive': 0.0,
      'varianceWithFYYear1': 4468.75788,
    },
    {
      'bauFYYear1': 7027.119689,
      'primary': 6144.0,
      'backup': 883.119689,
      'deepArchive': 0.0,
      'bauDatabaseStorage': 0.0,
      'totalCloudCosts': 2558.361809,
      'ebs': 2558.3616,
      's3': 0.0,
      'glacierPlusS3IA': 0.0,
      'fSxEFS': 0.0,
      'iops': 2.09e-4,
      'rdsStorage': 0.0,
      'remainingOnPremisePrimary': 0.0,
      'remainingOnPremiseBackup': 0.0,
      'remainingOnPremiseDeepArchive': 0.0,
      'varianceWithFYYear1': 4468.75788,
    },
    {
      'bauFYYear1': 7027.119689,
      'primary': 6144.0,
      'backup': 883.119689,
      'deepArchive': 0.0,
      'bauDatabaseStorage': 0.0,
      'totalCloudCosts': 2558.361809,
      'ebs': 2558.3616,
      's3': 0.0,
      'glacierPlusS3IA': 0.0,
      'fSxEFS': 0.0,
      'iops': 2.09e-4,
      'rdsStorage': 0.0,
      'remainingOnPremisePrimary': 0.0,
      'remainingOnPremiseBackup': 0.0,
      'remainingOnPremiseDeepArchive': 0.0,
      'varianceWithFYYear1': 4468.75788,
    },
    {
      'bauFYYear1': 35135.598445,
      'primary': 30720.0,
      'backup': 4415.598445,
      'deepArchive': 0.0,
      'bauDatabaseStorage': 0.0,
      'totalCloudCosts': 14548.58896725,
      'ebs': 12791.808,
      's3': 0.0,
      'glacierPlusS3IA': 0.0,
      'fSxEFS': 0.0,
      'iops': 0.0010450000000000001,
      'rdsStorage': 0.0,
      'remainingOnPremisePrimary': 1536.0,
      'remainingOnPremiseBackup': 220.77992225,
      'remainingOnPremiseDeepArchive': 0.0,
      'varianceWithFYYear1': 20587.00947775,
    },
  ],
};
export const computeCostAPIData = {
  'baseYear': 2022,
  'listofBAUCompute': [23166.225, 23166.225, 23166.225, 23166.225, 23166.225, 23166.225],
  'listofCloudCompute': [23166.225, 21205.78665, 19638.2304, 19638.2304, 19638.2304, 19638.2304],
};
export const bauVSCLoudLabels = {
  chartTitle: 'BAU Costs vs Cloud Costs(5 year TCO)',
  xAxisLabel: 'Cost (in $)',
  download: 'Download',
  exportChartName: 'BAU_VS_CLOUD_CHART',
  summaryType: 'ONDAMAND',
};

export const totalCostBreakDownkeys: string[] = [
  'Compute',
  'Database License',
  'Facilities',
  'Labor',
  'Migration',
  'Monitoring',
  'Network',
  'One Time Build Costs',
  'Storage',
];
export const bauVSCLoudAPIData = {
  'expense': '2036176.48',
  'variance': '65.15',
  'totalBAUCost': 3125405.0076899994,
  'totalAWSCost': 1089228.5362444702,
  'bauPerc': 0.7415603219378244,
  'awsPerc': 0.2584396780621755,
  'mapOfBAUCost': {
    'Analytics': '0',
    'Compute': '31351.13',
    'Database License': '167265',
    'Facilities': '2092728.54',
    'Labor': '431000',
    'Migration': '0',
    'Miscellaneous': '0',
    'Network': '367924.75',
    'One Time Build Costs': '0',
    'Storage': '35135.6',
  },
  'mapOfCloudCost': {
    'Analytics': '0',
    'Compute': '15278.71',
    'Database License': '167265',
    'Facilities': '104636.43',
    'Labor': '255342.1',
    'Migration': '30',
    'Miscellaneous': '462784',
    'Network': '29347.72',
    'One Time Build Costs': '39996',
    'Storage': '14548.59',
  },
};
/*End of Dynamic Chart Data*/

export const cfaOptions1: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
    className: 'five-yr-pl-chart-container',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: ['BAU', 'aws'],
    title: {
      text: 'Years',
    },
    labels: {
      x: -50,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'cost (in $)',
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color: 'gray',
      },
      formatter() {
        return Highcharts.numberFormat(this.total / 1000000, 2) + 'M';
      },
    },
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
    },
  },
  series: [
    {
      type: 'column',
      name: 'Compute',
      data: [0, 0],
    },
    {
      type: 'column',
      name: 'Datebase License',
      data: [0, 0],
    },
    {
      type: 'column',
      name: 'Facilities',
      data: [370000000, 670000000],
    },
    {
      type: 'column',
      name: 'Labor',
      data: [0, 0],
    },
    {
      type: 'column',
      name: 'Migration',
      data: [0, 0],
    },
    {
      type: 'column',
      name: 'Monitoring',
      data: [0, 0],
    },
    {
      type: 'column',
      name: 'Network',
      data: [0, 0],
    },
    {
      type: 'column',
      name: 'One Time Build Costs',
      data: [0, 0],
    },
    {
      type: 'column',
      name: 'Storage',
      data: [0, 0],
    },
  ],
};
export const cfaOptions2: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'line',
    className: 'five-yr-pl-chart-container',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: ['2017', '2018', '2019', '2020', '2021', '2022'],
    title: {
      text: 'Years',
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'cost (in $)',
    },
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
    },
  },
  series: [
    {
      type: 'line',
      name: 'BAU',
      data: [420000000, 420000000, 420000000, 420000000, 420000000, 420000000],
    },
    {
      type: 'line',
      name: 'aws',
      data: [420000000, 100000000, 0, 0, 0, 0],
    },
  ],
};
export const cfaOptions3: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'line',
    className: 'five-yr-pl-chart-container',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: ['2017', '2018', '2019', '2020', '2021', '2022'],
    title: {
      text: 'Years',
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'cost (in $)',
    },
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
    },
  },
  series: [
    {
      type: 'line',
      name: 'BAU',
      data: [420000000, 420000000, 420000000, 420000000, 420000000, 420000000],
    },
    {
      type: 'line',
      name: 'aws',
      data: [420000000, 100000000, 0, 0, 0, 0],
    },
  ],
};
export const cfaOptions4: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'line',
    className: 'five-yr-pl-chart-container',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: ['2017', '2018', '2019', '2020', '2021', '2022'],
    title: {
      text: 'Years',
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'cost (in $)',
    },
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
    },
  },
  series: [
    {
      type: 'line',
      name: 'BAU',
      data: [420000000, 420000000, 420000000, 420000000, 420000000, 420000000],
    },
    {
      type: 'line',
      name: 'aws',
      data: [420000000, 100000000, 0, 0, 0, 0],
    },
  ],
};

export const devopsGraphOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    polar: true,
    type: 'line',
  },
  title: {
    text: '',
    x: -80,
  },
  pane: {
    size: '80%',
  },
  xAxis: {
    categories: ['Plan', 'Monitor', 'Develop', 'Deploy', 'Test'],
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },

  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },
  tooltip: {
    shared: true,
    pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>',
  },
  series: [
    {
      name: 'GSFICC',
      type: 'line',
      data: [1, 2, 3, 2, 1],
      pointPlacement: 'on',
    },
    {
      name: 'Leader',
      type: 'line',
      data: [3, 7, 2, 8, 1],
      pointPlacement: 'on',
    },
  ],
};
export const operationModalAssesment: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  series: [
    {
      type: 'treemap',
      layoutAlgorithm: 'stripes',
      alternateStartingDirection: true,
      levels: [
        {
          level: 1,
          layoutAlgorithm: 'sliceAndDice',
          dataLabels: {
            enabled: true,
            align: 'left',
            verticalAlign: 'top',
            style: {
              fontSize: '10px',
              fontWeight: 'normal',
            },
          },
        },
      ],
      data: [
        {
          id: 'A',
          name: '',
          color: '#ffbf00',
        },
        {
          id: 'B',
          name: 'Organizations and Roles',
          color: '#ffbf00',
        },
        {
          id: 'C',
          name: 'Workforce and Culture',
          color: '#ffff00',
        },
        {
          id: 'D',
          name: 'Policy and Controls',
          color: '#ffff00',
        },
        {
          id: 'E',
          name: 'Governance and KPIs',
          color: '#ffff00',
        },
        {
          id: 'F',
          name: 'Cloud Economics',
          color: '#ffff00',
        },
        {
          id: 'G',
          name: 'Security Compliance',
          color: '#ffff00',
        },
        {
          id: 'H',
          name: 'Platform and Adoption',
          color: '#ffff00',
        },
        {
          id: 'I',
          name: 'Cloud Adoption',
          color: '#ffff00',
        },
        {
          id: 'J',
          name: 'Cloud Platform',
          color: '#ffff00',
        },
        {
          name: 'People and Talent 59%',
          parent: 'A',
          value: 100,
        },
        {
          name: '',
          parent: 'B',
          value: 75,
        },
        {
          name: 'Operating Model Redesign and Organization Structure',
          parent: 'B',
          value: 75,
        },
        {
          name: 'Roles and Responsibilities Definitions',
          parent: 'B',
          value: 75,
        },
        {
          name: 'IT and Business Interaction Model',
          parent: 'B',
          value: 75,
        },
        {
          name: '',
          parent: 'C',
          value: 35,
        },
        {
          name: 'Cloud Workforce Analytics',
          parent: 'C',
          value: 30,
        },
      ],
    },
  ],
  tooltip: {
    useHTML: true,
    pointFormat: 'The area of <b>{point.name}</b> is <b>{point.value} km<sup>2</sup></b>',
  },
};

export const monthlySavingTrackGraphOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'spline',
  },
  title: {
    text: 'Monthly Savings Tracking (in $)',
  },
  xAxis: {
    categories: [],
    accessibility: {
      description: 'M onths of the year',
    },
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return this.value + 'M';
      },
    },
  },
  plotOptions: {
    spline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Total Cloud Costs',
      type: 'spline',
      data: [57.5, 58, 61, 61, 54.5, 57.5, 57.5, 49.5, 49.5, 49.5, 49.5],
    },
    {
      name: 'On-Prem Costs',
      type: 'spline',
      color: '#eab676',
      data: [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
    },
  ],
};

export const annualSavingTrackGraphOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'spline',
  },
  title: {
    text: 'Annual Savings Tracking (in $)',
  },
  xAxis: {
    categories: ['2022', '2023', '2024', '2025', '2026', '2027'],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return this.value + 'M';
      },
    },
    // categories: ['580', '600','620','640','680']
  },
  plotOptions: {
    spline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Total Cloud Costs',
      type: 'spline',
      data: [600, 597, 597, 597, 597, 597],
    },
    {
      name: 'On-Prem Costs',
      type: 'spline',
      color: '#eab676',
      data: [600, 600, 600, 600, 600, 600],
    },
  ],
};

export const bizRealOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'spline',
  },
  title: {
    text: 'Monthly Savings Tracking (in $)',
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return this.value + 'M';
      },
    },
    categories: [],
  },
  plotOptions: {
    spline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Total Cloud Costs',
      type: 'spline',
      data: [50, 50, 42, 43, 38, 39, 44, 47, 49, 49, 49, 49],
    },
    {
      name: 'On-Prem Costs',
      type: 'spline',
      color: '#eab676',
      data: [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
    },
  ],
};

/** */

// Create the chart
export const pieChart1: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'pie',
  },
  title: {
    text: 'People and Telent',
    style: { fontSize: '2rem' },
  },
  subtitle: {
    text: '59 %',
    align: 'center',
    verticalAlign: 'middle',
    style: { fontSize: '2rem' },
    y: 170,
    x: -15,
  },
  plotOptions: {
    pie: {
      shadow: false,
      center: ['46%', '95%'],
    },
  },
  tooltip: {
    valueSuffix: '%',
  },
  series: [
    {
      name: 'Versions',
      type: 'pie',
      data: [
        { name: 'People and Telent', y: 59, color: '#7cb5ec' },
        { name: 'others', y: 41, color: '#434348' },
      ],
      size: '100%',
      innerSize: '60%',
      dataLabels: {
        enabled: false,
      },
      id: 'People',
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400,
        },
        chartOptions: {
          series: [
            {
              id: 'People',
              type: 'pie',
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
      },
    ],
  },
};
export const PieChart2: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'pie',
  },
  title: {
    text: 'Policy and Controls',
    style: { fontSize: '2rem' },
  },
  subtitle: {
    text: '51 %',
    align: 'center',
    verticalAlign: 'middle',
    style: { fontSize: '2rem' },
    y: 170,
    x: -15,
  },
  plotOptions: {
    pie: {
      shadow: false,
      center: ['46%', '95%'],
    },
  },
  tooltip: {
    valueSuffix: '%',
  },
  series: [
    {
      name: '',
      type: 'pie',
      data: [
        { name: 'policy and Controls', y: 51, color: '#7cb5ec' },
        { name: 'others', y: 49, color: '#434348' },
      ],
      size: '100%',
      innerSize: '60%',
      dataLabels: {
        enabled: false,
      },
      id: 'policy',
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400,
        },
        chartOptions: {
          series: [
            {
              id: 'policy',
              type: 'pie',
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
      },
    ],
  },
};

export const opSpiderGraphOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    polar: true,
    type: 'line',
  },
  title: {
    text: 'Assessment by Categories',
  },
  pane: {
    size: '100%',
  },
  xAxis: {
    categories: [
      'Cloud Operations',
      'Organization and Roles',
      'Workforce and Culture',
      'Governance and KPIs',
      'Could Economics',
      'Security and Compliance',
      'Cloud Adoption',
      'Cloud Adoption',
    ],
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },

  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },
  tooltip: {
    shared: true,
    pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>',
  },
  series: [
    {
      name: '',
      type: 'line',
      data: [75, 35, 35, 35, 35, 35, 35, 75],
      pointPlacement: 'on',
    },
  ],
};

export const costAllocationTrendOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Allocation Trend',
  },
  xAxis: {
    categories: [
      'Jan 22',
      'Feb 22',
      'Mar 22',
      'Apr 22',
      'May 22',
      'Jun 22',
      'Jul 22',
      'Aug 22',
      'Sep 22',
      'Oct 22',
      'Nov 22',
      'Dec 22',
      'Jan 23',
    ],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Total Shared Cost',
      type: 'line',
      data: [
        0.07801600000000004, 0, 0, 691.1320390000002, 1525.4690439999981, 2944.707759999995,
        2022.2810379999983, 1806.0274779999822, 0, 0, 0, 0, 2258.1967909999767,
      ],
    },
    {
      name: 'Allocated Cost',
      type: 'column',
      color: '#eab676',
      data: [
        0.07801600000000004, 0, 0, 691.1320390000002, 1525.4690439999988, 1472.3538799999974,
        2022.2810379999983, 1986.6461957999886, 0, 0, 0, 0, 2258.1967909999767,
      ],
    },
  ],
};

export const containerCostTrendOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Cost Trend',
  },
  xAxis: {
    categories: [
      'Apr 22',
      'May 22',
      'Jun 22',
      'Jul 22',
      'Aug 22',
      'Sep 22',
      'Oct 22',
      'Nov 22',
      'Dec 22',
      'Jan 23',
      'Feb 23',
      'Mar 23',
    ],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Trend',
      type: 'line',
      data: [
        16.118, 55.083, 54.863, 56.692, 56.692, 54.863, 72.114, 327.317, 576.589, 576.99, 520.812,
        142.529,
      ],
    },
    {
      name: 'Cost',
      type: 'column',
      color: '#86bc26',
      data: [
        16.118, 55.083, 54.863, 56.692, 56.692, 54.863, 72.114, 327.317, 576.589, 576.99, 520.812,
        142.529,
      ],
    },
  ],
};

export const tcoInsightsOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Total Cost',
  },
  xAxis: {
    categories: ['Compute', 'Storage', 'Network', 'Database', 'Software', 'Labor'],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  series: [
    {
      name: 'On Premise',
      type: 'column',
      color: '#7e8288',
      data: [1200, 0, 0, 0, 0, 60000],
      pointWidth: 20,
    },
  ],
};

export const consolidatedSummaryOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Cost Trend',
  },
  xAxis: {
    categories: [
      'Apr 22',
      'May 22',
      'Jun 22',
      'Jul 22',
      'Aug 22',
      'Sep 22',
      'Oct 22',
      'Nov 22',
      'Dec 22',
      'Jan 23',
      'Feb 23',
      'Mar 23',
    ],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Trend',
      color: '#86bc26',
      type: 'line',
      data: [
        726.102, 1724.143, 3558.754, 5752.815, 7675.816, 7229.167, 7555.678, 8080.769, 8917.311,
        8588.5411, 8446.2512, 3348.21,
      ],
    },
    {
      name: 'Cost',
      type: 'column',
      color: '#2a908f',
      data: [
        726.102, 1724.143, 3558.754, 5752.815, 7675.816, 7229.167, 7555.678, 8080.769, 8917.311,
        8588.5411, 8446.2512, 3348.21,
      ],
    },
  ],
};

export const costSavingsOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Cost Savings',
  },
  xAxis: {
    categories: [
      'Idle Instances',
      'Idle Disks',
      'Idle IP Addresses',
      'Idle Images',
      'Idle Cloud SQL Instances',
      'Overprovisioned Cloud SQL Instances',
      'Project Utilization',
    ],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Potential Savings',
      type: 'column',
      color: '#7e8288',
      data: [2201.724, 260.504, 216.058, 30, 28, 24, 15],
    },
    {
      name: 'Cost Saving In Progress',
      type: 'column',
      color: '#026fe3',
      data: [377.356, 10, 30.24, 5, 7, 10, 4],
    },
    {
      name: 'Cost Saved',
      type: 'column',
      color: '#61ad41',
      data: [5, 10, 15, 10, 10, 10, 10],
    },
  ],
};

export const costRecommendationSummary: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'line',
  },
  title: {
    text: undefined,
  },
  xAxis: {
    categories: ['Jan 23', 'Feb 23', 'Mar 23'],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Consumption Management',
      type: 'line',
      color: '#7e8288',
      data: [121.724, 200.504, 216.058],
    },
    {
      name: 'Purchase Tactics',
      type: 'line',
      color: '#026fe3',
      data: [30, 50, 105],
    },
    {
      name: 'Waste Management',
      type: 'line',
      color: '#61ad41',
      data: [0, 20, 48],
    },
  ],
};

export const wasteManagement: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'bar',
  },
  title: {
    text: undefined,
  },
  xAxis: {
    categories: ['Jan 23', 'Feb 23', 'Mar 23'],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Consumption Management',
      type: 'bar',
      color: '#7e8288',
      data: [121.724],
      pointWidth: 20,
    },
    {
      name: 'Purchase Tactics',
      type: 'bar',
      color: '#026fe3',
      data: [30],
      pointWidth: 20,
    },
    {
      name: 'Waste Management',
      type: 'bar',
      color: '#61ad41',
      data: [48],
      pointWidth: 20,
    },
  ],
};

export const costTrendOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Cost Trend',
  },
  xAxis: {
    categories: [
      'Apr 22',
      'May 22',
      'Jun 22',
      'Jul 22',
      'Aug 22',
      'Sep 22',
      'Oct 22',
      'Nov 22',
      'Dec 22',
      'Jan 23',
      'Feb 23',
      'Mar 23',
    ],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Trend',
      type: 'line',
      data: [0, 0, 0, 0, 16.118, 56.692, 72.114, 327.317, 576.589, 576.99, 520.812, 142.529],
    },
    {
      name: 'Cost',
      type: 'column',
      color: '#86bc26',
      data: [0, 0, 0, 0, 16.118, 56.692, 72.114, 327.317, 576.589, 576.99, 520.812, 142.529],
    },
  ],
};

export const cloudCostAllocationOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Allocation Trend',
  },
  xAxis: {
    categories: [
      'Apr 22',
      'May 22',
      'Jun 22',
      'Jul 22',
      'Aug 22',
      'Sep 22',
      'Oct 22',
      'Nov 22',
      'Dec 22',
      'Jan 23',
      'Feb 23',
      'Mar 23',
    ],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Trend',
      type: 'line',
      color: '#dc789c',
      data: [0, 0, 0, 2990, 2990, 2990, 2990, 2990, 2990, 2990, 3200, 3210],
    },
    {
      name: 'Cost',
      type: 'column',
      color: '#68629a',
      data: [0, 0, 0, 2990, 0, 0, 0, 0, 0, 0, 210, 0],
    },
  ],
};

export const quarterCostSummary: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'bar',
  },
  title: {
    text: undefined,
  },
  xAxis: {
    categories: ['QTC', 'Previus Quarter'],
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
    tickInterval: 5000,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      type: 'bar',
      color: '#468629',
      data: [23000, 22000],
      pointWidth: 20,
    },
  ],
};

export const costByResourceOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Top 10 resources',
  },
  xAxis: {
    type: 'category',
  },
  yAxis: {
    title: {
      text: 'cost',
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: undefined,
      type: 'column',
      colorByPoint: true,
      data: [
        {
          name: 'cb360synapsewrkspc',
          y: 17820,
        },
        {
          name: 'azpoc-bastion',
          y: 1100,
        },
        {
          name: 'java-functions-app-service-plan',
          y: 910,
        },
        {
          name: 'asp-rgcb360-903c',
          y: 900,
        },
        {
          name: 'recomm-vm1',
          y: 400,
        },
        {
          name: 'recomm-vm1',
          y: 390,
        },
        {
          name: 'redis-vm-poc',
          y: 350,
        },
        {
          name: 'mi-regression',
          y: 300,
        },
        {
          name: 'nat-azpoc-vnet',
          y: 200,
        },
        {
          name: 'null',
          y: 150,
        },
      ],
    },
  ],
};

export const ytdCostByResourceGroupOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: undefined,
  },
  xAxis: {
    title: {
      text: 'Date',
    },
    categories: ['Jan 23'],
  },
  yAxis: {
    title: {
      text: 'cost after credit',
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
  },
  series: [
    {
      name: 'rg_cb360',
      pointWidth: 20,
      type: 'column',
      data: [19900],
    },
    {
      name: 'rg_cb360_devops',
      pointWidth: 20,
      type: 'column',
      data: [3000],
    },
    {
      name: 'automl',
      pointWidth: 20,
      type: 'column',
      data: [500],
    },
    {
      name: 'null',
      pointWidth: 20,
      type: 'column',
      data: [400],
    },
    {
      name: 'defaultresourcegroup-eus',
      pointWidth: 20,
      type: 'column',
      data: [200],
    },
    {
      name: 'cloud-shell-storage-centralindia',
      pointWidth: 20,
      type: 'column',
      data: [300],
    },
    {
      name: 'cloud-shell-storage-southcentralus',
      pointWidth: 20,
      type: 'column',
      data: [100],
    },
  ],
};

export const carbonFootPrintOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'waterfall',
  },
  title: {
    text: 'Reduction/(Increase) in Carbon Footprint (in tons) due to Migration',
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter: function () {
          if (typeof this.y === 'undefined') {
            return 0;
          }
          var value = this.y;

          if (Math.abs(value) >= 1000000) {
            return Highcharts.numberFormat(value / 1000000, 2) + 'M';
          } else if (Math.abs(value) >= 1000) {
            return Highcharts.numberFormat(value / 1000, 2) + ' tons';
          } else {
            return Highcharts.numberFormat(value, 2);
          }
        },
        verticalAlign: 'top',
        y: -25,
      },
    },
  },
  xAxis: {
    title: {
      text: undefined,
    },
    categories: ['Pre-migration', 'Year 1', 'Year 2', 'Year 3', 'Post-migration'],
  },
  yAxis: {
    title: {
      text: undefined,
    },
  },
  series: [
    {
      type: 'waterfall',
      color: '#b0b0b0',
      data: [
        {
          name: 'Pre-migration',
          y: 435000,
          color: '#91ec7e',
        },
        {
          name: 'Year 1',
          y: -148000,
        },
        {
          name: 'Year 2',
          y: -94000,
        },
        {
          name: 'Year 3',
          y: -75000,
        },
        {
          name: 'Post-migration',
          isSum: true,
          color: '#91ec7e',
        },
      ],
      pointPadding: 0,
    },
  ],
};

export const carbonCreditsOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Dollar value of Carbon Credit received Year on Year',
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter: function () {
          // Format the label based on the value
          if (typeof this.y === 'undefined') {
            return 0;
          }
          var value = this.y;

          if (Math.abs(value) >= 1000000) {
            return '$' + Highcharts.numberFormat(value / 1000000, 2) + 'M';
          } else if (Math.abs(value) >= 1000) {
            return '$' + Highcharts.numberFormat(value / 1000, 2) + 'K';
          } else {
            return '$' + Highcharts.numberFormat(value, 2);
          }
        },
        verticalAlign: 'top',
        y: -25,
      },
    },
  },
  xAxis: {
    title: {
      text: undefined,
    },
    categories: ['Year 1', 'Year 2', 'Year 3', 'Total'],
  },
  yAxis: {
    title: {
      text: undefined,
    },
  },
  series: [
    {
      type: 'column',
      color: '#b0b0b0',
      data: [
        {
          name: 'Year 1',
          y: 1640,
        },
        {
          name: 'Year 2',
          y: 1040,
        },
        {
          name: 'Year 3',
          y: 833,
        },
        {
          name: 'Post-migration',
          y: 3520,
          color: '#91ec7e',
        },
      ],
    },
  ],
};

export const energySavingsOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'waterfall',
  },
  title: {
    text: 'Reduction/(Increase) in Energy Consumption (in KWh) due to Migration',
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter: function () {
          if (typeof this.y === 'undefined') {
            return 0;
          }
          const value = this.y;

          if (Math.abs(value) >= 1000000) {
            return Highcharts.numberFormat(value / 1000000, 2) + 'M KWh';
          } else if (Math.abs(value) >= 1000) {
            return Highcharts.numberFormat(value / 1000, 2) + 'K KWh';
          } else {
            return Highcharts.numberFormat(value, 2) + 'KWh';
          }
        },
        verticalAlign: 'top',
        y: -25,
      },
    },
  },
  xAxis: {
    title: {
      text: undefined,
    },
    categories: ['Pre-migration', 'Year 1', 'Year 2', 'Year 3', 'Post-migration'],
  },
  yAxis: {
    title: {
      text: undefined,
    },
  },
  series: [
    {
      type: 'waterfall',
      color: '#b0b0b0',
      data: [
        {
          name: 'Pre-migration',
          y: 528910,
          color: '#91ec7e',
        },
        {
          name: 'Year 1',
          y: -120250,
        },
        {
          name: 'Year 2',
          y: -75620,
        },
        {
          name: 'Year 3',
          y: -65280,
        },
        {
          name: 'Post-migration',
          isSum: true,
          color: '#91ec7e',
        },
      ],
      pointPadding: 0,
    },
  ],
};

export const applicationMigrationSequenceOptions: Highcharts.Options = {
  chart: {
    type: 'dumbbell',
    inverted: true,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },

  legend: {
    enabled: false,
  },

  subtitle: {
    text:
      '1970 vs 2021 Source: ' +
      '<a href="https://ec.europa.eu/eurostat/en/web/main/data/database"' +
      'target="_blank">Eurostat</a>',
  },

  xAxis: {
    title: {
      text: undefined,
    },
  },
  yAxis: {
    title: {
      text: undefined,
    },
    categories: [],
  },
  series: [
    {
      type: 'dumbbell',
      data: [
        {
          name: 'Austria',
          low: 70.1,
          high: 81.3,
        },
        {
          name: 'Belgium',
          low: 71.0,
          high: 81.9,
        },
      ],
    },
  ],
};

export const bpMonthlyOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'spline',
  },
  title: {
    text: 'Monthly savings tracking (in $)',
  },
  xAxis: {
    title: {
      text: 'Savings (in $)',
    },
    categories: [],
  },
  yAxis: {
    title: {
      text: undefined,
    },
    categories: [],
    labels: {
      formatter: function () {
        return `${this.value}M`;
      },
    },
  },
  tooltip: {
    valueSuffix: ' M',
  },
  series: [
    {
      name: 'Total Cloud Costs',
      type: 'spline',
      color: '#44b9fd',
      data: [57, 58, 60.5, 60.5, 53.5, 58, 58, 49.6, 49.5, 49.5, 49.5],
      //pointPadding: 0,
    },
    {
      name: 'On-Prem Costs',
      type: 'spline',
      color: '#e8a658',
      data: [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
      //pointPadding: 0,
    },
  ],
};

export const bpAnnualOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'spline',
  },
  title: {
    text: 'Annual savings tracking (in $)',
  },
  xAxis: {
    title: {
      text: 'Savings (in $)',
    },
    categories: ['2022', '2023', '2024', '2025', '2026', '2027'],
  },
  yAxis: {
    title: {
      text: undefined,
    },
    categories: [],
    labels: {
      formatter: function () {
        return `${this.value}M`;
      },
      step: 20,
    },
  },
  tooltip: {
    valueSuffix: ' M',
  },
  series: [
    {
      name: 'Total Cloud Costs',
      type: 'spline',
      color: '#44b9fd',
      data: [600, 655, 590, 590, 590, 590],
      //pointPadding: 0,
    },
    {
      name: 'On-Prem Costs',
      type: 'spline',
      color: '#e8a658',
      data: [600, 600, 600, 600, 600, 600],
      //pointPadding: 0,
    },
  ],
};

export const brMonthlyOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'spline',
  },
  title: {
    text: 'Monthly savings tracking (in $)',
  },
  xAxis: {
    title: {
      text: 'Savings (in $)',
    },
    categories: [],
  },
  yAxis: {
    title: {
      text: undefined,
    },
    categories: [],
    labels: {
      formatter: function () {
        return `${this.value}M`;
      },
    },
  },
  tooltip: {
    valueSuffix: ' M',
  },
  series: [
    {
      name: 'Total Cloud Costs',
      type: 'spline',
      color: '#44b9fd',
      data: [50, 50.2, 42, 43, 37.5, 38.5, 43.5, 46.5, 49, 49, 49, 49],
      //pointPadding: 0,
    },
    {
      name: 'On-Prem Costs',
      type: 'spline',
      color: '#e8a658',
      data: [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
      //pointPadding: 0,
    },
  ],
};

export const spendDiagnosisOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'pie',
  },
  title: {
    text: 'APR 22 - Mar 23',
    align: 'left',
  },
  plotOptions: {
    pie: {
      shadow: false,
      center: ['50%', '50%'],
    },
  },
  tooltip: {
    valueSuffix: '%',
  },
  series: [
    {
      type: 'pie',
      name: 'gcp budget',
      data: [
        {
          name: 'EU-east',
          y: 50,
          color: '#f2d2f1',
        },
        {
          name: 'US-east',
          y: 30,
          color: '#ddf312',
        },
        {
          name: 'EU-central',
          y: 8,
          color: '#ddaa12',
        },
        {
          name: 'US-west',
          y: 12,
          color: '#eed312',
        },
      ],
      size: '80%',
      innerSize: '60%',
      id: 'gcp-budget',
    },
  ],
};

export const spendOptimizationOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'bar',
  },
  title: {
    text: undefined,
  },
  xAxis: {
    categories: ['Recommendations'],
    labels: {
      step: 500,
    },
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}`;
      },
    },
    categories: [],
  },
  plotOptions: {
    areaspline: {
      marker: {},
    },
  },
  series: [
    {
      name: 'Consumption Management',
      type: 'bar',
      color: '#7e8288',
      data: [4100],
      pointWidth: 20,
    },
    {
      name: 'Purchase Tactics',
      type: 'bar',
      color: '#026fe3',
      data: [100],
      pointWidth: 20,
    },
    {
      name: 'Waste Management',
      type: 'bar',
      color: '#61ad41',
      data: [30],
      pointWidth: 20,
    },
  ],
};

export const costVsCustomBuildOptions: Highcharts.Options = {
  chart: {
    type: 'pie',
  },
  title: {
    text: 'COTS Vs Custom Build',
    align: 'left',
  },
  tooltip: {
    valueSuffix: '%',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  series: [
    {
      type: 'pie',
      name: 'gcp budget',
      data: [
        {
          name: 'COTS - Heavy Customization',
          y: 32,
          color: '#6498f6',
        },
        {
          name: 'COTS - Light Customization',
          y: 29,
          color: '#6cd8ac',
        },
        {
          name: 'COTS - No Customization',
          y: 21,
          color: '#667897',
        },
        {
          name: 'Custom Build',
          y: 18,
          color: '#f5bd3d',
        },
      ],
      size: '80%',
      id: 'gcp-budget',
    },
  ],
};

export const applicationSequence: Highcharts.Options = {
  chart: {
    type: 'xrange',
    inverted: false,
  },
  title: {
    text: 'Application Migration Sequence',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },

  accessibility: {
    point: {
      descriptionFormatter: function (point) {
        var ix = point.index,
          category = point.category,
          from = point.x,
          to = point.x2;
        return ix + category + ', ' + from?.toString() + ' to ' + to?.toString() + '.';
      },
    },
  },
  legend: {
    enabled: true,
  },
  xAxis: {
    categories: [
      'Feb 22',
      'March 22',
      'April 22',
      'May 22',
      'June 22',
      'July 22',
      'Aug 22',
      'Sept 22',
      'Oct 22',
      'Nov 22',
    ],
    reversed: false,
  },
  yAxis: {
    categories: [
      'App1',
      'App2',
      'App3',
      'App4',
      'App5',
      'App6',
      'App7',
      'App8',
      'App9',
      'App10',
      'App11',
      'App12',
      'App13',
      'App14',
    ],
    reversed: false,
    title: {
      text: 'Applications',
    },
  },
  series: [
    {
      name: 'Refactor',
      color: '#3c619e',
      showInLegend: true,
      pointWidth: 5,
      type: 'xrange',
      data: [
        {
          x: 0,
          x2: 2,
          y: 1,
          color: '#3c619e',
        },
        {
          x: 2,
          x2: 3,
          y: 3,
          color: '#3c619e',
        },
        {
          x: 8,
          x2: 9,
          y: 13,
          color: '#3c619e',
        },
      ],
    },

    {
      name: 'Replatform',
      color: 'yellow',
      pointWidth: 5,
      showInLegend: true,
      type: 'xrange',
      data: [
        {
          x: 1,
          x2: 3,
          y: 2,
          color: '#f07ac7',
        },
        {
          x: 2.5,
          x2: 4,
          y: 4,
          color: '#f07ac7',
        },
        {
          x: 5.5,
          x2: 6.3,
          y: 10,
          color: '#f07ac7',
        },
      ],
    },

    {
      name: 'Rehost',
      color: 'green',
      pointWidth: 5,
      type: 'xrange',
      data: [
        {
          x: 3.5,
          x2: 4.5,
          y: 5,
          color: 'green',
        },
        {
          x: 4.5,
          x2: 5.5,
          y: 7,
          color: 'green',
        },
        {
          x: 5.5,
          x2: 6,
          y: 9,
          color: 'green',
        },
        {
          x: 7,
          x2: 8,
          y: 12,
          color: 'green',
        },
      ],
    },

    {
      name: 'Relocate',
      color: 'red',
      pointWidth: 5,
      type: 'xrange',
      data: [
        {
          x: 3.5,
          x2: 5.5,
          y: 6,
          color: 'red',
        },
        {
          x: 4.5,
          x2: 5.5,
          y: 8,
          color: 'red',
        },
        {
          x: 6,
          x2: 7,
          y: 11,
          color: 'red',
        },
      ],
    },
  ],
};

export const monthlySavingGraph: Highcharts.Options = {
  chart: {
    type: 'column',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: 'Savings(in $)',
  },
  accessibility: {
    point: {
      valueDescriptionFormat: '{index}.  {xDescription}, {value}%.',
    },
  },
  xAxis: [
    {
      categories: [
        'Feb 23',
        'Mar 23',
        'Apr 22',
        'May 22',
        'Jun 22',
        'Jul 22',
        'Aug 22',
        'Sep 22',
        'Oct 22',
        'Nov 22',
        'Dec 22',
        'Jan 23',
      ],
      reversed: false,
      labels: {
        step: 1,
      },
      accessibility: {
        description: '',
      },
    },
  ],
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return `${this.value}M`;
      },
    },
  },
  plotOptions: {
    column: {
      dataLabels: {
        style: { color: 'black' },
        enabled: true,
        formatter: function () {
          return `${this.y}M`;
        },
        inside: false,
        padding: 9,
      },
    },
    series: {
      stacking: 'normal',
    },
  },
  series: [
    {
      name: 'Owning to migration effort',
      color: '#ff80aa',
      type: 'column',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: 'Owning to landing zone costs',
      color: '#b3b3b3',
      type: 'column',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: 'Owning to cloud-run costs',
      color: '#ff8080',
      type: 'column',
      data: [-7.24, -8.03, -10.63, -10.63, -11.6, -16.37, -16.37, -17.28, -17.22, -17.22, -17.22],
    },
    {
      name: 'Savings',
      color: '#2eb82e',
      type: 'column',
      data: [0, 0, 1.8, 1.8, 7.63, 7.89, 7.89, 18.04, 18.04, 18.04, 18.04],
    },
  ],
};

/** Cloud ops model journey graphs starts */

export const wcaOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    polar: true,
    margin: 15,
    type: 'line',
  },
  title: {
    text: undefined,
    floating: true,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    categories: [
      'Cloud Workforce Analytics',
      'Cloud Training and Talent Strategy',
      'Cloud Culture and Service Transition',
    ],
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },
  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },
  series: [
    {
      type: 'line',
      data: [70, 80, 80],
    },
  ],
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y:,.0f}',
  },
};

export const governanceKPIOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    polar: true,
    margin: 15,
    type: 'line',
  },
  title: {
    text: undefined,
    floating: true,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    categories: [
      'Governance Strategy',
      'Governing Standards, Policies and Enterprise Architecture',
      'Performance Management and KPIs',
      'Vendor Management',
    ],
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },
  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },
  series: [
    {
      type: 'line',
      data: [0, 50, 85, 70],
    },
  ],
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y:,.0f}',
  },
};

export const securityComplianceOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    polar: true,
    margin: 15,
    type: 'line',
  },
  title: {
    text: undefined,
    floating: true,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    categories: ['Security Policies and  Controls', 'Cloud Vigilance', 'Cloud Resilience'],
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },
  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },
  series: [
    {
      type: 'line',
      data: [50, 70, 50],
    },
  ],
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y:,.0f}',
  },
};

export const cloudAdoptionOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    polar: true,
    margin: 15,
    type: 'line',
  },
  title: {
    text: undefined,
    floating: true,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    categories: [
      'Cloud Native Enablement',
      'Devops Enablement',
      'Migration Factory',
      'BU Onboarding and Training',
    ],
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },
  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },
  series: [
    {
      type: 'line',
      data: [75, 50, 80, 70],
    },
  ],
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y:,.0f}',
  },
};

export const cloudOperationsOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    polar: true,
    margin: 15,
    type: 'line',
  },
  title: {
    text: undefined,
    floating: true,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    categories: [
      'Logging and Monitoring',
      'Infrastructure / Platform Management',
      'Security Operation',
      'Cost Management',
      'Cloud Availability',
    ],
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },
  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },
  series: [
    {
      type: 'line',
      data: [55, 75, 90, 80, 90],
    },
  ],
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y:,.0f}',
  },
};
/** Cloud ops model journey graphs starts */

export const heatmapChartAPIData = {
  xAxisLabels: [
    'Total No. Of Application',
    'App Regulatory & Con',
    'Application Instability',
    'Business Criticality',
    'Component Coupling',
    'Data Classification',
    'Flash Storage Used',
    'Impact due to data loss',
    'RPO Requirements',
    'Software Cost',
    'Support Level',
  ],
  yAxisLabels: ['Bus1', 'Bus2', 'Bus3', 'Bus4', 'Bus5'],
  data: [
    [0, 0, 10],
    [0, 1, 19],
    [0, 2, 8],
    [0, 3, 24],
    [0, 4, 67],
    [1, 0, 0],
    [1, 1, 0],
    [1, 2, 0],
    [1, 3, 0],
    [1, 4, 0],
    [2, 0, 35],
    [2, 1, 15],
    [2, 2, 123],
    [2, 3, 64],
    [2, 4, 52],
    [3, 0, 0],
    [3, 1, 0],
    [3, 2, 0],
    [3, 3, 0],
    [3, 4, 0],
    [4, 0, 100],
    [4, 1, 100],
    [4, 2, 100],
    [4, 3, 100],
    [4, 4, 100],
    [5, 0, 88],
    [5, 1, 32],
    [5, 2, 12],
    [5, 3, 6],
    [5, 4, 120],
    [6, 0, 13],
    [6, 1, 44],
    [6, 2, 88],
    [6, 3, 98],
    [6, 4, 96],
    [7, 0, 31],
    [7, 1, 1],
    [7, 2, 82],
    [7, 3, 32],
    [7, 4, 30],
    [8, 0, 85],
    [8, 1, 97],
    [8, 2, 123],
    [8, 3, 64],
    [8, 4, 84],
    [9, 0, 100],
    [9, 1, 100],
    [9, 2, 100],
    [9, 3, 100],
    [9, 4, 100],
    [10, 0, 47],
    [10, 1, 114],
    [10, 2, 31],
    [10, 3, 48],
    [10, 4, 91],
  ],
};

export const projectId = '4259';

export const assetAuthorisationtLabel = 'Configure >';

export const costTrendByService: Highcharts.Options = {
  chart: {
    type: 'column',
  },
  title: {
    text: 'Cost Trend - By Service',
    align: 'center',
  },
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: ['Sep-21', 'Aug-21', 'Jul-21', 'Jun-21', 'May-21'],
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Cost Trend',
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return Highcharts.numberFormat(this.total * 1, 0) + '$';
      },
    },
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false,
        format: '{point.y:f}',
      },
    },
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      type: 'column',
      data: [50000, 62200, 200000, 60000, 80000],
    },
    {
      type: 'column',
      data: [40000, 30700, 100000, 20000, 72000],
    },
    {
      type: 'column',
      data: [40000, 30040, 50080, 50900, 30700],
    },
    {
      type: 'column',
      data: [30000, 50000, 200003, 40030, 70030],
    },
    {
      type: 'column',
      data: [21872, 50000, 12000, 60004, 50004],
    },
  ],
};
